import React from 'react';
import './ourExpertiseSection.scss';
import ImagesContainer from "./ImagesContainer/ImagesContainer";
import {useTranslation} from "react-i18next";

const OurExpertiseSection = () => {
    const { t } = useTranslation();

    return (
        <div id={'expertise'} className={'responsive-container responsive-container__slimmer'}>
            <div className={'intro'}>
                <h3>{t('OurExpertise')}</h3>
                <p className={'text-container'}>{t('OurExpertiseMessageParagraph')}</p>
            </div>
            <ImagesContainer />
        </div>
    )
}

export default OurExpertiseSection;