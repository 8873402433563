export const footerContent = {
    contact: {
        address: [
            'Hexad Spain S.L.',
            'Carrer de Mallorca Nº 100, Bajos.',
            '08029 - Barcelona',
            'Spain.'
        ],
        phone: [
            '+34 663 963 154'
        ],
        email: [
            'office.spain@hexad.es'
        ]
    },
    locations: [
        'Barcelona',
        'Wolfsburg',
        'Bangalore'
    ],
    social: [
        {
            text: 'Linkedin',
            link: 'https://www.linkedin.com/company/hexad-spain-sl/'
        }
    ]
}
