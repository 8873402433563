import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    hasError: false,
    error: {}
}

const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setError(state, action) {
            state.hasError = true;
            state.error = action.payload;
        },
        resetError(state) {
            state.hasError = initialState.hasError;
            state.error = initialState.error;
        }
    }
})

export const { setError, resetError } = errorSlice.actions

export default errorSlice.reducer
