import React from 'react';
import './errorPage.scss';
import HexadButton from "../../Components/HexadButton/HexadButton";
import {useTranslation} from "react-i18next";
import errorImage from '../../styles/images/error_page.gif';
import {useDispatch} from "react-redux";
import {resetError} from "../../redux/slices/errorSlice";
import OutsideAlerter from "../../Navigation/OutsideAlerter/OutsideAlerter";

const ErrorPage = ({error}) => {
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const handleOnClick = () => {
        dispatch(resetError());
    }

    return (
        <OutsideAlerter onOutside={handleOnClick}>
            <div id={'error-page'} data-testid={'error-page'}>
                <div className={'top-area'} />
                <div className={'error-container'}>
                    <h2>{error?.status ? error.status : '404'}</h2>
                    <h3>{`${t('ErrorMessage')} :(`}</h3>
                    <HexadButton text={t('GoBack')} link={'/'} inverted onClick={handleOnClick}/>
                </div>
                <img src={errorImage} alt={'Hexad guy with error'} />
            </div>
        </OutsideAlerter>
    )
}

export default ErrorPage;