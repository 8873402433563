import React from 'react';
import './clientsLogoContainer.scss';

const ClientsLogoContainer = () => {
    let imageNames = [];
    const directory = require.context('../../../../../styles/images/clientLogos/', false, /\.(png|jpe?g|svg)$/);
    directory.keys().map(item => imageNames.push(item.replace("./", "")));

    let images = [];
    imageNames.map(path => images.push(require('../../../../../styles/images/clientLogos/' + path)));

    return (
        <div id={'clients-logo-container'} className={'wider'}>
            <ul className={'image-gallery'}>
                {images.map((image, index) => {
                    return (
                        <li className={'image-container'} key={index} >
                            <img src={image} alt={`image-${index}`} />
                        </li>)}
                )}
            </ul>
        </div>

    )
}

export default ClientsLogoContainer;

