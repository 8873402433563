import React from 'react';
import './hexadHome.scss';
import AboutUsSection from "./Sections/AboutUsSection/AboutUsSection";
import IntroSection from "./Sections/IntroSection/IntroSection";
import SmallImageSection from "./Sections/SmallImageSection/SmallImageSection";
import WhoWeAreSection from "./Sections/WhoWeAreSection/WhoWeAreSection";
import OurExpertiseSection from "./Sections/OurExpertiseSection/OurExpertiseSection";
import CarouselSection from "./Sections/CarouselSection/CarouselSection";
import ClientsSection from "./Sections/ClientsSection/ClientsSection";
import BuildSection from "./Sections/BuildSection/BuildSection";

const HexadHome = () => {

    return (
        <div className={'hexad-home'}>
            <IntroSection />
            <AboutUsSection />
            <SmallImageSection />
            <WhoWeAreSection />
            <OurExpertiseSection />
            <CarouselSection />
            <ClientsSection />
            <BuildSection />
        </div>
    )
}

export default HexadHome;