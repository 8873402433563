import React from 'react';
import './popupAlert.scss';
import HexadButton from "../HexadButton/HexadButton";
import {resetAlert} from "../../redux/slices/alertSlice";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const PopupAlert = () => {
    const dispatch = useDispatch();
    const {message} = useSelector(state => state.alert);
    const {t} = useTranslation();

    const handleAlertButton = () => {
        dispatch(resetAlert());
    }

    return (
        <div id={'popup-alert'}>
            <div className={'alert-container responsive-container responsive-container__slimmer'}>
                {message}
                <HexadButton link={'#'} text={t('GotIt')} onClick={handleAlertButton} inverted />
            </div>
        </div>
    )
}

export default PopupAlert;