import axios from "axios";
import {setError} from "./redux/slices/errorSlice";
import {resetForm, setErrMessage} from "./redux/slices/contactSlice";
import {setAlert} from "./redux/slices/alertSlice";

export const sendContactUsEmail = (name, email, phone, message, dispatch, t) => {
    axios.put(
        `${window.location.origin}/auth-service/organizations/9/authentications`,
        {
            username: 'frontend.mesh@hexad.de',
            password: 'Frontend@123'
        },
        {
            headers: {
                CONTENT_TYPE: 'application/json'
            }
        }
    )
        .then(response => {
            axios.post(`${window.location.origin}/push-notification-service/organizations/9/email`, {
                to: ["office.spain@hexad.de"],
                emailTemplateEnum: "CONTACT_US",
                messageProperties: {
                    name: name,
                    email: email,
                    phone: phone,
                    message: message
                }
            }, {
                headers: {
                    Authorization: `Bearer ${response.data.accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(() => {
                    dispatch(resetForm());
                    dispatch(setErrMessage({}));
                    dispatch(setAlert({
                        showAlert: true,
                        message: t("EmailSent"),
                    }));
                    return true;
                })
                .catch(error => {
                    console.error(error);
                    dispatch(setAlert({
                        showAlert: true,
                        message: t("EmailNotSent"),
                    }));
                    dispatch(setError({
                        status: error.response?.status,
                        statusText: error.response?.statusText
                    }));
                    dispatch(resetForm());
                    return false;
                });
        })
        .catch(error => {
            console.error(error);
            dispatch(setAlert({
                showAlert: true,
                message: t("EmailNotSent"),
            }));
            dispatch(setError({
                status: error.response?.status,
                statusText: error.response?.statusText
            }));
            dispatch(resetForm());
            return false;
        });
}
