import React from "react";
import './list.scss';
import ListItem from "./ListItem/ListItem";

const List = ({content, listType, external}) => {
    return (
        <div id={'list'}>
            <ul>{content.map((item, index) =>
                    <ListItem key={index} type={!!listType && 'link'} item={item} external={!!external}/>
                )}</ul>
        </div>
    )
}

export default List;