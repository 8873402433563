import React from 'react';
import './cookiePolicy.scss';
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import {useTranslation} from "react-i18next";

const CookiePolicy = () => {
    const { t } = useTranslation();

    return (
        <div id={'cookie-policy'}>
            <div className={'top-area'} />
            <div className="privacy-policy-container responsive-container responsive-container__slimmer">
                <Breadcrumbs />
                <h3>{t('CookiePolicy')}</h3>
                <div className={'blocks'}>
                    <h4>{t('WebsiteHolder')}</h4>
                </div>
            </div>
        </div>
    )
}

export default CookiePolicy;