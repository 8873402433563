import React from 'react';
import './introSection.scss';
import HexadButton from "../../../../Components/HexadButton/HexadButton";
import backgroundImage from '../../../../styles/images/background-logo-white.svg';
import {useTranslation} from "react-i18next";

const IntroSection = () => {
    const { t } = useTranslation();
    return (
        <div id={'intro-section'} data-testid={'intro-section'}>
            <div className={'intro-content responsive-container responsive-container__slimmer'}>
                <h2>{t('IntroMessageHeader')} <span>Barcelona</span>.</h2>
                <p className={'text-container'}>{t('IntroMessageParagraph')}</p>
                <HexadButton text={t('GetInTouch')} link={'/get-in-touch'}/>
            </div>
            <img src={backgroundImage} alt={'hexad-logo'} />
        </div>
    )
}

export default IntroSection;