import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import {useDispatch, useSelector} from "react-redux";
import {updateForm} from "../../../../redux/slices/contactSlice";

const PhoneNumberInput = ({onBlur}) => {
    const dispatch = useDispatch()
    const {formData} = useSelector(state => state.form);

    const handlePhoneNumber = (phone) => {
        dispatch(updateForm({...formData, 'phone': phone}));
    }

    return (
        <PhoneInput
            defaultCountry="ES"
            data-testid={'phone'}
            placeholder="+34 000 000 000"
            value={formData?.phone}
            onChange={handlePhoneNumber}
            onBlur={() => onBlur()}
            name="phone"
        />
    )
}

export default PhoneNumberInput;