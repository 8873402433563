import React from 'react';
import './contactForm.scss';
import HexadButton from "../../../Components/HexadButton/HexadButton";
import {updateForm, setErrMessage} from "../../../redux/slices/contactSlice";
import {useDispatch, useSelector} from 'react-redux';
import PhoneNumberInput from "./PhoneNumberInput/PhoneNumberInput";
import { useTranslation } from 'react-i18next';
import { sendContactUsEmail } from '../../../api.js';
import {isValidPhoneNumber} from "react-phone-number-input";
import {Link} from "react-router-dom";
import {handleSubmitErrors} from "./handleSubmitErrors";

const ContactForm = () => {
    const dispatch = useDispatch();
    const {formData, errMessage} = useSelector(state => state.form);
    const { t } = useTranslation();

    const isEmailValid = () => {
        const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

        if (!!formData.email && emailRegex.test(formData.email)) {
            dispatch(setErrMessage({...errMessage, email: ''}));
            !errMessage.phone && !errMessage.terms && dispatch(setErrMessage({}));
            return true;
        }
        dispatch(setErrMessage({...errMessage, email: t("InvalidEmail")}));
        return false;
    }

    const isPhoneValid = () => {
        if (!!formData.phone && isValidPhoneNumber(formData.phone) || !formData.phone) {
            dispatch(setErrMessage({...errMessage, phone: ''}));
            !errMessage.email && !errMessage.terms && dispatch(setErrMessage({}));
            return true;
        }
        dispatch(setErrMessage({...errMessage, phone: t("InvalidPhone")}));
        return false;
    }

    const areTermsAccepted = () => formData.terms;

    const handleFormData = (e, tag) => {
        e.preventDefault();
        const data = e.target.value;
        dispatch(updateForm({...formData, [tag]: data}));
    }

    const handleTermsAndConditions = () => {
        const value = formData.terms;
        dispatch(updateForm({...formData, terms: !value}));

        if (!value) {
            const { ['terms']: unused, ...rest } = errMessage;
            dispatch(setErrMessage(rest));
        } else {
            dispatch(setErrMessage({...errMessage, terms: t("AcceptTerms")}))
        }
    }

    const handleSubmit = () => {
        if (isEmailValid() && isPhoneValid() && areTermsAccepted()) {
            sendContactUsEmail(
                    formData?.fname + ' ' + formData?.lname,
                    formData?.email,
                    formData?.phone,
                    formData?.message,
                    dispatch,
                    t
                );
        } else {
            dispatch(handleSubmitErrors(areTermsAccepted, isEmailValid, isPhoneValid, errMessage, t));
        }
    }

    return (
        <form id={'contact-form'} data-testid={'contact-form'}>
            <div className={'contact-name'}>
                <div className={'contact-pair'}>
                    <label htmlFor="fname"><h5>{t('FirstName')}</h5></label>
                    <input type="text" id="fname" name="fname" data-testid={'name'} value={formData?.fname} onChange={e => handleFormData(e, 'fname')} placeholder={t('FirstName')} />
                </div>
                <div className={'contact-pair'}>
                    <label htmlFor="lname"><h5>{t('LastName')}</h5></label>
                    <input type="text" id="lname" name="lname" value={formData?.lname} onChange={e => handleFormData(e, 'lname')} placeholder={t('LastName')} />
                </div>
            </div>
            <div className={`contact-pair ${errMessage?.phone && 'error'}`}>
                <label htmlFor="phone"><h5>{t('PhoneNumber')}</h5></label>
                <PhoneNumberInput onBlur={isPhoneValid}/>
                {errMessage?.phone && <span className="error-message" data-testid={'error-message-phone'}>{errMessage.phone}</span>}
            </div>
            <div className={`contact-pair ${errMessage?.email && 'error'}`}>
                <label htmlFor="email"><h5>Email *</h5></label>
                <input
                    type="text"
                    id="email"
                    data-testid={'email'}
                    name="email"
                    value={formData?.email}
                    onChange={e => handleFormData(e, 'email')}
                    placeholder={t('EmailExample')}
                    onBlur={isEmailValid}
                    required
                />
                {errMessage?.email && <span className="error-message" data-testid={'error-message-email'}>{errMessage.email}</span>}
            </div>
            <div className={'contact-pair'}>
                <label htmlFor="message"><h5>{t('Message')}</h5></label>
                <textarea id="message" name="message" rows="8" cols="50" value={formData?.message} onChange={e => handleFormData(e, 'message')} placeholder={t('Type') + '...'} />
            </div>
            <div className={`terms-and-conditions ${errMessage?.terms && 'error'}`}>
                <input type={'checkbox'} id="terms" name="terms" checked={formData?.terms} onChange={() => handleTermsAndConditions()} />
                <p>{t('IconfirmTerms')} <Link to={'/legal-notice'}>{t('LegalNotice')}</Link> {t('And')} <Link to={'/privacy-policy'}>{t('PrivacyPolicy')}</Link>.</p>
                {errMessage?.terms && <span className="error-message">{errMessage.terms}</span>}
            </div>
            <div className="submit-container">
                <HexadButton link='#' text={t('SendMessage')} inverted small onClick={handleSubmit} />
                {errMessage?.onSubmit && <span className="error-message" data-testid={'submit-error'}>{errMessage.onSubmit}</span>}
            </div>
        </form>
    )
}

export default ContactForm;