import React from 'react';
import './listItem.scss';
import {Link} from "react-router-dom";

const ListItem = ({item, type, external}) => {
    return (
        <div id="list-item">
            {!!type && type === 'link'
                ? <li><Link to={item.link} target={!!external ? "_blank" : undefined}>{item.text}</Link></li>
                : <li><p>{item}</p></li>}
        </div>
    )

}

export default ListItem;