import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    showAlert: false,
    message: 'Alert'
}

const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        setAlert(state, action) {
            state.showAlert = action.payload.showAlert;
            state.message = action.payload.message;
        },
        resetAlert(state) {
            state.showAlert = initialState.showAlert;
            state.message = initialState.message;
        }
    }
})

export const { setAlert, resetAlert } = alertSlice.actions

export default alertSlice.reducer
