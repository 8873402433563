import React, {useState, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import './navigation.scss';
import HexadHome from "../pages/HexadHome/HexadHome";
import Career from "../pages/Career/Career";
import GetInTouch from "../pages/GetInTouch/GetInTouch";
import HexadHeader from "../HexadHeader/HexadHeader";
import Footer from "../Components/Footer/Footer";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import PopupAlert from "../Components/PopupAlert/PopupAlert";
import {useSelector} from "react-redux";
import FooterBottom from "../Components/Footer/FooterBottom/FooterBottom";
import LegalNotice from "../pages/LegalNotice/LegalNotice";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import CookiePolicy from "../pages/CookiePolicy/CookiePolicy";
import ScrollToTop from "../Components/ScrollToTop/ScrollToTop";

const Navigation = () => {
    const [fixedHeader, setFixedHeader] = useState(false);
    const {showAlert} = useSelector(state => state.alert);

    useEffect(() => {
        if (typeof window !== "undefined") {
            window.addEventListener("scroll", () =>
                setFixedHeader(window.scrollY > 100)
            );
        }
    }, []);

    return (
        <Router>
            <ScrollToTop />
            <div className={`container ${fixedHeader ? "fixed" : ""}`}>
                {showAlert && <PopupAlert />}
                <HexadHeader fixed={fixedHeader}/>
                <ErrorBoundary>
                    <Routes>
                        <Route path="/" element={<HexadHome />} />
                        <Route path="/career" element={<Career />} />
                        <Route path="/get-in-touch" element={<GetInTouch />} />
                        <Route path="/legal-notice" element={<LegalNotice />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="/cookie-policy" element={<CookiePolicy />} />
                        <Route path="*" element={<ErrorPage />} />
                    </Routes>
                </ErrorBoundary>
                <Footer />
                <FooterBottom />
            </div>
        </Router>
    );
}

export default Navigation;
