import React from 'react';
import './footer.scss';
import { HashLink } from 'react-router-hash-link';
import hexadWhite from "../../styles/images/white-full-logo.svg";
import {footerContent} from "./footerContent";
import List from "../List/List";
import { useTranslation } from 'react-i18next';
import Address from "../Address/Address";

const Footer = () => {
    const {locations, social} = footerContent;
    const { t } = useTranslation();

    return (
        <div id={'footer'} className={'responsive-container responsive-container__slimmer'}>
            <HashLink to={'/#intro-section'}>
                <img src={hexadWhite} alt={'Hexad logo'} className={'hexad-logo'} />
            </HashLink>
            <Address withHeader />
            <div className={'locations'}>
                <h4>{t('Locations')}</h4>
                <List content={locations} />
            </div>
            <div className={'social'}>
                <h4>{t('Social')}</h4>
                <List content={social} listType external />
            </div>
        </div>
    )
}

export default Footer;