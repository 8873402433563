import React, {useState} from 'react';
import './languageSelector.scss';
import {useTranslation} from "react-i18next";
import OutsideAlerter from "../../Navigation/OutsideAlerter/OutsideAlerter";

const LanguageSelector = ({language}) => {
    const { i18n } = useTranslation();
    const [languageOpen, setLanguageOpen] = useState(false);


    const handleLanguages = (event) => {
        i18n.changeLanguage(event.target.value);
        setLanguageOpen(!languageOpen);
    }

    return (
        <div id={'select-language'}>
            <OutsideAlerter onOutside={() => setLanguageOpen(false)}>
                <span className={'solidGlobe'}  onClick={() => setLanguageOpen(!languageOpen)}/>
                {languageOpen &&
                        <ul className={'language-dropdown'} onChange={handleLanguages}>
                            {language !== 'en' &&
                                <li>
                                    <input type='radio' value='en' name='radio' id='english'/>
                                    <label htmlFor='english'>English</label>
                                </li>}
                            {language !== 'es' &&
                                <li>
                                    <input type='radio' value='es' name='radio' id='spanish'/>
                                    <label htmlFor='spanish'>Español</label>
                                </li>}
                        </ul>
                }
            </OutsideAlerter>
        </div>
    )
}

export default LanguageSelector;