import React, {useState, useRef, useEffect} from 'react';
import './imagesContainer.scss';
import HexadButton from "../../../../../Components/HexadButton/HexadButton";
import {useTranslation} from "react-i18next";
import layout from "../../../../../core-sass/_layout.scss";

const ImagesContainer = () => {
    const { t } = useTranslation();
    const ref = useRef();

    const [rowCount, setRowCount] = useState(2);
    const [allImagesVisible, setAllImagesVisible] = useState(false);

    const imageHeight = 104;
    const paddingLaptop = 40;
    const paddingMobile = 24;
    const rowHeightLaptop = imageHeight + paddingLaptop;
    const rowHeightMobile = imageHeight + paddingMobile;
    const htmlWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const rowHeight = htmlWidth > Number(layout.width_md) ? rowHeightLaptop : rowHeightMobile;
    const [height, setHeight] = useState(rowHeight * rowCount);

    let imageNames = [];
    const directory = require.context('../../../../../styles/images/expertiseLogos/', false, /\.(png|jpe?g|svg)$/);
    directory.keys().map(item => imageNames.push(item.replace("./", "")));

    let images = [];
    imageNames.map(path => images.push(require('../../../../../styles/images/expertiseLogos/' + path)));

    useEffect(() => {
        function isLastImageHidden(imageElement) {
            const imageElementPosition = imageElement.getBoundingClientRect().bottom;

            const container = document.getElementsByClassName('images-container')[0].getBoundingClientRect();
            const containerPosition = container.top + height;

            const isElementVisible = imageElementPosition < containerPosition;

            if (isElementVisible) {
                setAllImagesVisible(true);
            }
        }

        const imageElement = ref.current;
        isLastImageHidden(imageElement);
    }, [rowCount, height]);

    const onLoadMore = () => {
        setRowCount(rowCount + 1);
        setHeight(rowHeight * (rowCount + 1));
    }

    return (
        <div id={'images-container'}>
            <ul className={'images-container'} style={{height: height}}>
                {images.map((image, index) => {
                    return (
                        <li id={`image-container`} className={'image-container'} key={index} >
                            <img ref={ref} src={image} alt={`background-${index}`} />
                        </li>)}
                    )}
            </ul>
            {!allImagesVisible && <HexadButton text={t('LoadMore')} inverted onClick={onLoadMore} />}
        </div>

    )
}

export default ImagesContainer;

