import React from 'react';
import './hexadButton.scss';
import {Link} from "react-router-dom";

const HexadButton = ({text, inverted, link = '/', external, onClick = () => {}, small}) => {

    return (
        <Link
            to={link}
            target={!!external ? '_blank' : undefined}
            id={'hexad-button'}
            data-testid={'hexad-button'}
            className={`hexad-button ${!!inverted && 'inverted'} ${!!small && 'small'}`}
            onClick={() => onClick()}>
            {text}
        </Link>
    )
}

export default HexadButton;