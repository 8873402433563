import './App.scss';
import Navigation from "./Navigation/Navigation";

function App() {

  return (
    <div className="App" data-testid={'App'}>
        <Navigation />
    </div>
  );
}

export default App;
