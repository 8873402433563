import React from 'react';
import './whoWeAreSection.scss';
import Feature from "../../../../Components/Feature/Feature";
import {useTranslation} from "react-i18next";

const WhoWeAreSection = () => {
    const { t } = useTranslation();
    const features = ['Innovation & Creativity', 'Agile', 'Human-centric', 'Diversity'];

    return (
        <div id={'who-we-are'} className={'responsive-container responsive-container__slimmer'}>
            <div className={'intro'}>
                <h3>{t('WhoWeAreMessageHeader')}</h3>
                <p className={'text-container'}>{t('WhoWeAreMessageParagraph')}</p>
            </div>
            <div className={'features-grid'}>
                {features.map((feature, index) => {
                    feature = t(feature.replace(/ /g, ''));
                    return <Feature feature={feature} key={index} />
                })}
            </div>
        </div>
    )
}

export default WhoWeAreSection;