import React from 'react';
import './feature.scss';
import featureImage from '../../styles/images/color-icon-logo.svg';

const Feature = ({feature}) => {
    return (
        <div className={'feature-component'}>
            <img src={featureImage} alt="hexad logo" className="feature-image"/>
            <p>{feature}</p>
        </div>
    )
}

export default Feature;