import React from 'react';
import './carouselSection.scss';
import shimmerImage from '../../../../styles/images/shimmer-filled-logo.png';
import hoverLogo from '../../../../styles/images/white-icon-logo.svg';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import layout from './../../../../core-sass/_layout.scss';
import { useTranslation } from 'react-i18next';

const CarouselSection = () => {
    const { t } = useTranslation();

    let imageNames = [];
    const directory = require.context('../../../../styles/images/officeImages/', false, /\.(png|jpe?g|svg)$/);
    directory.keys().map(item => imageNames.push(item.replace("./", "")));

    let images = [];
    imageNames.map(path => images.push(require('../../../../styles/images/officeImages/' + path)));

    const indentLaptop = 87 + 14 + 23;
    const indentMobile = 40 + 14 + 18;
    const rightIndentLaptop = indentLaptop + 16 * 2 * images.length + 23;
    const rightIndentMobile = indentMobile + 10 * 2 * images.length + 18;
    const htmlWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const rightIndent = htmlWidth > Number(layout.width_md) ? rightIndentLaptop : rightIndentMobile;


    const renderArrowPrev = (clickHandler, hasPrev) => {
        return (
            hasPrev && <span className={'regularChevronLeft'} style={{right: rightIndent}} onClick={clickHandler}/>
        )
    }

    const renderArrowNext = (clickHandler, hasNext) => {
        return (
            hasNext && <span className={'regularChevronRight'} onClick={clickHandler} />
        )
    }

    const CarouselComponent = () => {
        return (
            <Carousel
                renderArrowPrev={(clickHandlerPrev, hasPrev) => renderArrowPrev(clickHandlerPrev, hasPrev)}
                renderArrowNext={(clickHandlerNext, hasNext) => renderArrowNext(clickHandlerNext, hasNext)}
                autoPlay
                infiniteLoop
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={50}
            >
                {images.map((image, index) => {
                    return (
                        <div className={'responsive-carousel'} key={index}>
                            <div className={'responsive-container__slimmer'}>
                                <h2 className="headline">{t("OurOfficeInBarcelona")}</h2>
                            </div>
                            <img className={'shimmer'} src={shimmerImage} alt={'shimmer'} />
                            <div className={'overlay'} />
                            <img className={'background'} src={image} alt={t("OurOfficeInBarcelona")} />
                            <img className={'hover-logo'} src={hoverLogo} alt="White icon logo" />
                        </div>
                    )
                })}
            </Carousel>
        )
    }

    return (
        <div id={'carousel'}>
            {CarouselComponent()}
        </div>
    )
}

export default CarouselSection;