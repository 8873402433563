import { configureStore } from '@reduxjs/toolkit';
import authReducer from "./slices/authSlice";
import contactReducer from './slices/contactSlice';
import errorReducer from './slices/errorSlice';
import alertReducer from './slices/alertSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        form: contactReducer,
        error: errorReducer,
        alert: alertReducer
    }
});

export default store;
