import React from 'react';
import './clientsSection.scss';
import ClientsLogoContainer from "./ClientsLogoContainer/ClientsLogoContainer";
import { useTranslation } from 'react-i18next';

const ClientsSection = () => {
    const { t } = useTranslation();

    return (
        <div id={'clients'} className={'responsive-container'}>
            <div className={'wider'}>
                <h3>{t('OurClientsAndPartners')}</h3>
                <p className={'text-container'}>{t('OurClientsAndPartnersMessage')}</p>
            </div>
            <ClientsLogoContainer />
        </div>
    )
}

export default ClientsSection;