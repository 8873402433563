import React from "react";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import {useSelector} from 'react-redux';

const ErrorBoundary = (props) => {
    const {error} = useSelector(state => state);

    return (
        error.hasError
            ? <ErrorPage error={error.error} />
            : props.children
    );
}

export default ErrorBoundary;