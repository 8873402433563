import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    user: undefined
}

const authSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        addUser(state, action) {
            state.user = action.payload;
        },
        deleteUser(state) {
            state.user = undefined;
        }
    }
})

export const { addUser, deleteUser } = authSlice.actions

export default authSlice.reducer
