import React from 'react';
import './address.scss';
import List from "../List/List";
import {footerContent} from "../Footer/footerContent";
import {useTranslation} from "react-i18next";

const Address = ({withHeader}) => {
    const {contact} = footerContent;
    const { t } = useTranslation();

    return (
        <div id={'contact'}>
            {withHeader && <h4>{t('Contact')}</h4>}
            <div>
                <h5>{t('Address')}</h5>
                <List content={contact.address} />
            </div>
            <div>
                <h5>{t('PhoneNumber')}</h5>
                <List content={contact.phone} />
            </div>
            <div>
                <h5>Email</h5>
                <List content={contact.email} />
            </div>
        </div>
    )
}

export default Address;