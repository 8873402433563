import React from 'react';
import './buildCard.scss';
import HexadButton from "../../../../../Components/HexadButton/HexadButton";

const BuildCard = ({content}) => {
    const {headline, text, buttonText, buttonLink} = content;

    return (
        <div className={'build-card'}>
            <div className={'title'}>
                <h4>{headline}</h4>
                <p>{text}</p>
            </div>
            <HexadButton text={buttonText} link={buttonLink} />
        </div>
    )
}

export default BuildCard;