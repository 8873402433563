import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    formData: {
        fname: '',
        lname: '',
        phone: '',
        email: '',
        message: '',
        terms: false
    },
    errMessage: {}
}

const contactSlice = createSlice({
    name: 'contact form data',
    initialState,
    reducers: {
        updateForm(state, action) {
            state.formData = action.payload;
        },
        resetForm(state) {
            state.formData = initialState.formData;
        },
        setErrMessage(state, action) {
            state.errMessage = action.payload;
        }
    }
})

export const { updateForm, resetForm, setErrMessage } = contactSlice.actions

export default contactSlice.reducer
