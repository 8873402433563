import React from 'react';
import './privacyPolicy.scss';
import {Trans, useTranslation} from "react-i18next";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import MultilineTranslation from "../../Components/MultilineTranslation/MultilineTranslation";

const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <div id={'privacy-policy'}>
            <div className={'top-area'} />
            <div className="privacy-policy-container responsive-container responsive-container__slimmer">
                <Breadcrumbs />
                <h3>{t('PrivacyPolicyAndDataProtection')}</h3>
                <div className={'blocks'}>
                    <div>
                        <h4>{t('LawsIncorporated')}</h4>
                        {MultilineTranslation(t('LawsIncorporatedText'))}
                    </div>
                    <div>
                        <h4>{t('PersonalDataTreatment')}</h4>
                        <div>
                            <h5>{t('WebsiteHolder')}</h5>
                            <ul>
                                <li><strong>{t('Company')}:</strong>Hexad Spain, S.L</li>
                                <li><strong>{t('Address')}:</strong>
                                    <span>Carrer de Mallorca 100 - Bajos</span>
                                    <span>08029 - Barcelona (España)</span>
                                </li>
                                <li><strong>{t('VATnumber')}:</strong>B66644790</li>
                                <li><strong>{t('EmailAddress')}:</strong>office.spain@hexad.es</li>
                            </ul>
                        </div>
                    </div>

                    <div>
                        <h4>{t('DataProtectionDelegate')}</h4>
                        {MultilineTranslation(t('DataProtectionDelegateText'))}
                    </div>

                    <div>
                        <h4>{t('RegistrationPersonalData')}</h4>
                        {MultilineTranslation(t('RegistrationPersonalDataText'))}
                    </div>

                    <div>
                        <h4>{t('ApplicablePrinciplesPersonalData')}</h4>
                        <Trans>{MultilineTranslation(t('ApplicablePrinciplesPersonalDataText'))}</Trans>
                    </div>

                    <div>
                        <h4>{t('PersonalDataCategories')}</h4>
                        {MultilineTranslation(t('PersonalDataCategoriesText'))}
                    </div>

                    <div>
                        <h4>{t('LegalBasePersonalData')}</h4>
                        {MultilineTranslation(t('LegalBasePersonalDataText'))}
                    </div>

                    <div>
                        <h4>{t('PurposesOfProcessing')}</h4>
                        {MultilineTranslation(t('PurposesOfProcessingText'))}
                    </div>

                    <div>
                        <h4>{t('PersonalDataRetentionPeriod')}</h4>
                        <p>{t('PersonalDataRetentionPeriodText')}</p>
                    </div>

                    <div>
                        <h4>{t('RecipientsPersonalData')}</h4>
                        <p>{t('RecipientsPersonalDataText')}</p>
                    </div>

                    <div>
                        <h4>{t('PersonalDataMinors')}</h4>
                        <p>{t('PersonalDataMinorsText')}</p>
                    </div>

                    <div>
                        <h4>{t('SecrecyAndSecurity')}</h4>
                        {MultilineTranslation(t('SecrecyAndSecurityText'))}
                    </div>

                    <div>
                        <h4>{t('RightsFromProcessing')}</h4>
                        <Trans>{MultilineTranslation(t('RightsFromProcessingText'))}</Trans>
                    </div>

                    <div>
                        <h4>{t('ClaimsControlAuthority')}</h4>
                        <p>{t('ClaimsControlAuthorityText')}</p>
                    </div>

                    <div>
                        <h4>{t('AcceptanceAndChanges')}</h4>
                        {MultilineTranslation(t('AcceptanceAndChangesText'))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;