import React from 'react';
import './aboutUs.scss';
import { useTranslation } from 'react-i18next';

const AboutUsSection = () => {
    const { t } = useTranslation();

    return (
        <div id={'about-us'} className={'responsive-container responsive-container__slimmer'}>
            <h3>{t('AboutUs')}</h3>
            <p className={'text-container'}>{t('AboutUsMessage')}</p>
        </div>
    )
}

export default AboutUsSection;