import React from 'react';
import './buildSection.scss';
import BuildCard from "./BuildCard/BuildCard";
import { useTranslation } from 'react-i18next';

const BuildSection = () => {
    const { t } = useTranslation();

    const buildContent = [
        {
            id: 'ID1',
            headline: t("Career"),
            text: t("LikeWhatYouSaw"),
            buttonText: t("KnowMore"),
            buttonLink: '/career'
        },
        {
            id: 'ID2',
            headline: t("GetInTouch"),
            text: t("GotAQuestionForUs"),
            buttonText: t("ContactUs"),
            buttonLink: '/get-in-touch'
        }
    ]
    return (
        <div id={'build'} className={'responsive-container responsive-container__slimmer'}>
            <h3>{t('BuildSomethingGreat')}</h3>
            <div className={'build-card-container'}>
                {buildContent.map(content => <BuildCard content={content} key={content.id} />)}
            </div>
        </div>
    )
}

export default BuildSection;