import React, {useEffect} from 'react';
import './career.scss';
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import HexadButton from "../../Components/HexadButton/HexadButton";
import careerImage from '../../styles/images/Hexad-office-bcn(4).jpg';
import {useTranslation} from "react-i18next";

const Career = () => {
    const { t } = useTranslation();
    const imageText = t('ImageText1') + t('ImageText2');
    const linkedinCareerPageLink = 'https://www.linkedin.com/jobs/search/?currentJobId=3550710690&f_C=10985016&geoId=92000000&originToLandingJobPostings=3550710690%2C3547839004%2C3542478353%2C3518134102';

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div id={'career'}>
            <div className={'top-area'} />
            <div className="career-container">
                <div className={'career-content responsive-container responsive-container__slimmer'}>
                    <Breadcrumbs />
                    <h3>{t('Career')}</h3>
                    <p>{t('LikeWhatYouSaw')}</p>
                    <HexadButton text={t('SeeJobs')} link={linkedinCareerPageLink} inverted external />
                </div>

                <div className={'career-image'}>
                    <div className="headline-container">
                        <h4 className={'headline'}>{imageText}</h4>
                    </div>
                    <div className={'overlay'} />
                    <img className={'background'} src={careerImage} alt="career image" />
                </div>
            </div>
        </div>
    )
}

export default Career;