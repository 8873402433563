import {setErrMessage} from "../../../redux/slices/contactSlice";

export const handleSubmitErrors = (areTermsAccepted, isEmailValid, isPhoneValid, errMessage, t) => {
    if (!areTermsAccepted()) {
        if (!isEmailValid()) {
            if (!isPhoneValid()) {
                return setErrMessage({
                    ...errMessage,
                    onSubmit: t("InvalidEmailOrPhone"),
                    email: t("InvalidEmail"),
                    phone: t("InvalidPhone"),
                    terms: t('AcceptTerms')
                })
            }
            return setErrMessage({
                ...errMessage,
                onSubmit: t("InvalidEmailOrPhone"),
                email: t("InvalidEmail"),
                terms: t('AcceptTerms')
            })
        }
        if (!isPhoneValid()) {
            return setErrMessage({
                ...errMessage,
                onSubmit: t("InvalidEmailOrPhone"),
                phone: t("InvalidPhone"),
                terms: t('AcceptTerms')
            })
        }
        return setErrMessage({
            ...errMessage,
            onSubmit: t("InvalidEmailOrPhone"),
            terms: t('AcceptTerms')
        })
    }
    if (!isEmailValid()) {
        if (!isPhoneValid()) {
            return setErrMessage({
                ...errMessage,
                onSubmit: t("InvalidEmailOrPhone"),
                email: t("InvalidEmail"),
                phone: t("InvalidPhone"),
            })
        }
        return setErrMessage({
            ...errMessage,
            onSubmit: t("InvalidEmailOrPhone"),
            email: t('InvalidEmail')
        })
    }
    if (!isPhoneValid()) {
        return setErrMessage({
            ...errMessage,
            onSubmit: t("InvalidEmailOrPhone"),
            phone: t('InvalidPhone')
        })
    }
    return setErrMessage({
        ...errMessage,
        onSubmit: t("InvalidEmailOrPhone"),
    })
}