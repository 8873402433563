import React from 'react';
import './legalNotice.scss';
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import {useTranslation} from "react-i18next";
import MultilineTranslation from "../../Components/MultilineTranslation/MultilineTranslation";

const LegalNotice = () => {
    const { t } = useTranslation();

    return (
        <div id={'legal-notice'}>
            <div className={'top-area'} />
            <div className="legal-notice-container responsive-container responsive-container__slimmer">
                <Breadcrumbs />
                <h3>{t('LegalNotice')}</h3>
                <div className={'blocks'}>
                    <div>
                        <h4>{t('WebsiteHolder')}</h4>
                        <ul>
                            <li>Hexad Spain, S.L</li>
                            <li>Carrer de Mallorca 100 - Bajos</li>
                            <li>08029 - Barcelona (España)</li>
                        </ul>
                    </div>

                    <ul>
                        <li><strong>{t('VATnumber')}:</strong>B66644790</li>
                        <li><strong>{t('RegistredIn')} Registro Mercantil de Barcelona:</strong>
                            <span>Tomo 45087,</span>
                            <span>Folio 199,</span>
                            <span>Hoja B 476436,</span>
                            <span>Inscripción 1</span>
                        </li>
                        <li><strong>{t('RepresentedBy')}:</strong>Suresh Shamanna</li>
                        <li><strong>{t('EmailAddress')}:</strong>office.spain@hexad.es</li>
                    </ul>

                    <div>
                        <h4>{t('TermsOfUse')}</h4>
                        {MultilineTranslation(t('TermsOfUseText'))}
                    </div>

                    <div>
                        <h4>{t('IntellectualProperty')}</h4>
                        {MultilineTranslation(t('IntellectualPropertyText'))}
                    </div>

                    <div>
                        <h4>{t('ResponsabilityExemption')}</h4>
                        {MultilineTranslation(t('ResponsabilityExemptionText'))}
                    </div>

                    <div>
                        <h4>{t('ExternalLinks')}</h4>
                        {MultilineTranslation(t('ExternalLinksText'))}
                    </div>

                    <div>
                        <h4>{t('ApplicableLegislation')}</h4>
                        {MultilineTranslation(t('ApplicableLegislationText'))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LegalNotice;