import React from 'react';
import './smallImageSection.scss';

const SmallImageSection = () => {
    return (
        <div id={'small-image-section'}>
            <div className="parallax"></div>
        </div>
    )
}

export default SmallImageSection;