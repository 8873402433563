import React, {useEffect} from 'react';
import './getInTouch.scss';
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import ContactForm from "./ContactForm/ContactForm";
import { useTranslation } from 'react-i18next';
import GoogleMaps from "./GoogleMaps/GoogleMaps";
import HexadButton from "../../Components/HexadButton/HexadButton";
import Address from "../../Components/Address/Address";

const GetInTouch = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div id={'get-in-touch'} data-testid={'get-in-touch'}>
            <div className={'top-area'} />
            <div className={'get-in-touch-content'}>
                <div className={'address-container responsive-container responsive-container__slimmer'}>
                    <Breadcrumbs />
                    <h3 data-testid={'headline'}>{t('GetInTouch')}</h3>
                    <p>{t('GotAQuestionForUs')}</p>
                    <Address />
                    <div className={'map-container'}>
                        <div className={'map'}>
                            <GoogleMaps />
                        </div>
                        <HexadButton
                            text={t('GoToGoogleMaps')}
                            link={'https://goo.gl/maps/wsQMLdSy8q54g6o36'}
                            external
                            small
                            inverted />
                    </div>
                </div>

                <div className={'contact-us-container responsive-container responsive-container__wider'}>
                    <div className={'header-container'}>
                        <h4>{t('ContactUs')}</h4>
                        <h5>{t('WeWouldLoveToHearFromYou')}</h5>
                    </div>
                    <ContactForm />
                </div>
            </div>
        </div>
    )
}

export default GetInTouch;