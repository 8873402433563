import React from 'react';
import './googleMaps.scss';

const GoogleMaps = () => {
    return (
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.4670655043283!2d2.1500832715852907!3d41.38566209734871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a397302c8f91%3A0xb112b43d10bd2798!2sHexad%20Spain%20SL!5e0!3m2!1sen!2ses!4v1680704613428!5m2!1sen!2ses"
            width="600"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade">
        </iframe>
    )
}

export default GoogleMaps;