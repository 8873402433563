import React from "react";
import './multilineTranslation.scss';

const MultilineTranslation = (text) => {
    const items = text.split('\n')
    return (
        <div id={'multiline-translation'}>
            {items.map((item, index) => <p key={index}>{item}</p>)}
        </div>
    )
}

export default MultilineTranslation;